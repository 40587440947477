import React, { useEffect, useState } from 'react'
import { Routes, Route, useParams } from 'react-router-dom'

import { getDatabase, ref, onValue, limitToFirst, query, get, remove } from 'firebase/database'
import app from '../helpers/firebase'

import Hotel from './Hotel'
import HotelList from './HotelList'

function App() {
  const db = getDatabase(app)

  const params = useParams()

  const [hotel, setHotel] = useState({
    name: '',
    description: '',
    facilities: [],
  })

  const [facilityIds, setFacilities] = useState({})

  const [hotels, setHotels] = useState({})
  const [hotelsPriceList, setHotelsPriceList] = useState({})

  useEffect(() => {
    onValue(ref(db, 'facilities'), snap => {
      setFacilities(snap.val())
    })

    onValue(ref(db, 'hotels-breif'), snap => {
      setHotels(snap.val())
    })

    onValue(ref(db, 'hotels-price-short'), snap => {
      setHotelsPriceList(snap.val())
    })

    console.log(params)

    // get(query(ref(db, `hotels/${params.hotelCode}`))).then(snap => {
    //   const h = snap.val()
    //   setHotel(h)
    // })
  }, [])

  return (
    <Routes>
      <Route path="/hotels" element={<HotelList hotels={hotels} hotelsPriceList={hotelsPriceList} />} />
      <Route path="/hotel/:hotelCode" element={<Hotel hotel={hotel} facilityIds={facilityIds} />} />
    </Routes>
  )
}

export default App
