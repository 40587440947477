import { initializeApp } from 'firebase/app'

const app = initializeApp({
  apiKey: 'AIzaSyCQAv5g2SjApTnqtTvYPLzRnpa6WEHMAwU',
  authDomain: 'resort-finder.firebaseapp.com',
  databaseURL: 'https://resort-finder-default-rtdb.firebaseio.com',
  projectId: 'resort-finder',
  storageBucket: 'resort-finder.appspot.com',
  messagingSenderId: '132005600310',
  appId: '1:132005600310:web:1b0754980d2afbf2ea0c97',
  measurementId: 'G-0JK4C106SE',
})

export default app
