import React from 'react'
import { Link } from 'react-router-dom'

import { MdStar, MdStarHalf, MdStarOutline } from 'react-icons/md'

function HotelList({ hotels, hotelsPriceList }) {
  console.log(hotels, hotelsPriceList)

  Object.keys(hotels).forEach(code => {
    hotels[code].price = {
      min: hotelsPriceList[code] ? hotelsPriceList[code].minRate : undefined,
      max: hotelsPriceList[code] ? hotelsPriceList[code].minRate : undefined,
      dailyMin: hotelsPriceList[code] ? roundDollar(hotelsPriceList[code].minRate / 5) : undefined,
      dailyMax: hotelsPriceList[code] ? roundDollar(hotelsPriceList[code].maxRate / 5) : undefined,
    }
  })

  const hotelsAsArray = Object.values(hotels).sort((a, b) => {
    if (!a.price.min && !b.price.min) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    }
    if (a.price.min && b.price.min) {
      return a.price.min - b.price.min
    }
    if (a.price.min && !b.price.min) {
      return -1
    }
    if (!a.price.min && b.price.min) {
      return 1
    }
    return 0
  })

  return (
    <table>
      {hotelsAsArray.map(hotel => (
        <Link to={`/hotel/${hotel.code}`}>
          <tr key={hotel.code}>
            <td>{catToStars(hotel.categoryCode)}</td>
            <td>{hotel.code}</td>
            <td>{hotel.name}</td>
            <td>{hotel.city}</td>
            <td>{hotel.boards && hotel.boards.AI ? '🤙' : ''}</td>
            <td>{hotel.price.dailyMin}</td>
            <td>{hotel.price.dailyMax}</td>
          </tr>
        </Link>
      ))}
    </table>
  )
}

export default HotelList

function roundDollar(number) {
  return Math.round(number * 100) / 100
}

function catToStars(category) {
  if (category === '2EST') {
    return (
      <>
        <MdStar />
        <MdStar />
        <MdStarOutline />
        <MdStarOutline />
        <MdStarOutline />
      </>
    )
  }

  if (category === 'H2_5') {
    return (
      <>
        <MdStar />
        <MdStar />
        <MdStarHalf />
        <MdStarOutline />
        <MdStarOutline />
      </>
    )
  }

  if (category === '3EST' || category === 'APTH3') {
    return (
      <>
        <MdStar />
        <MdStar />
        <MdStar />
        <MdStarOutline />
        <MdStarOutline />
      </>
    )
  }

  if (category === 'H3_5') {
    return (
      <>
        <MdStar />
        <MdStar />
        <MdStar />
        <MdStarHalf />
        <MdStarOutline />
      </>
    )
  }

  if (category === '4EST' || category === '4LL' || category === 'APTH4') {
    return (
      <>
        <MdStar />
        <MdStar />
        <MdStar />
        <MdStar />
        <MdStarOutline />
      </>
    )
  }

  if (category === '4LUX') {
    return (
      <>
        <div style={{ color: 'red' }}>
          <MdStar />
          <MdStar />
          <MdStar />
          <MdStar />
          <MdStarOutline />
        </div>
      </>
    )
  }

  if (category === 'H4_5' || category === 'SUP') {
    return (
      <>
        <MdStar />
        <MdStar />
        <MdStar />
        <MdStar />
        <MdStarHalf />
      </>
    )
  }

  if (category === '5EST') {
    return (
      <>
        <MdStar />
        <MdStar />
        <MdStar />
        <MdStar />
        <MdStar />
      </>
    )
  }

  if (category === '5LUX' || category === 'H5_5') {
    return (
      <>
        <div style={{ color: 'red' }}>
          <MdStar />
          <MdStar />
          <MdStar />
          <MdStar />
          <MdStar />
        </div>
      </>
    )
  }

  if (category === 'VILLA') {
    return <>VILLA</>
  }

  if (category === 'BOU') {
    return <>BOUTIQUE</>
  }

  return <>?????</>
}
