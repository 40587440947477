import React from 'react'

function Hotel({ hotel: { name, description, facilities }, facilityIds }) {
  const result = (
    <div>
      <div>
        <h2>{name}</h2>
      </div>
      <div>{description}</div>
      {Object.entries(facilities).map(([groupCode, xs]) => (
        <>
          <div>
            <h3>{facilityIds[groupCode].description.content}</h3>
          </div>
          {Object.values(xs).map(x => (
            <div style={{ marginLeft: '1rem', display: 'flex' }}>
              {facilityIds[groupCode].codes[x.facilityCode].description.content}
              {x.indFee && <div style={{ fontWeight: 500, color: '#66CDAA' }}>($)</div>}
              {x.number && <div style={{ fontWeight: 500, color: '#CC6688' }}>({x.number})</div>}
              {x.indYesOrNo === false && <div style={{}}>(👀)</div>}
            </div>
          ))}
        </>
      ))}
    </div>
  )

  return result
}

export default Hotel
